import React from 'react';
import styled from 'styled-components';
import { useState } from 'react'
import { lessonsList } from '../../datas/lessonsList'
import LessonsItem from '../LessonsItems/index';
import Categories from '../Catégories/index'
import '../../utils/styles/keyframes.css'


const ParentLessons = styled.div`
display: flex;
flex-direction: row;
align-items: center;
font-size: 16px;
font-family: Lato, sans-serif;
z-index: 2;

color: black;
border-radius: 10px;
background: transparent ;

@media screen and (min-width: 1900px) {
   width : 88%;
   font-size : 25px;
   margin-right : 5%;
   margin-bottom : 8%;
  }

@media (max-width: 480px) {
    flex-direction : column;
    margin-bottom : 12%;
 }

`

const LessonsDiv = styled.div`
z-index: 2;
flex-basis: 10%;
text-align: center;
padding-right: 0%;
padding-left: 0%;
text-transform: uppercase;
@media screen and (min-width: 1920px) {
    margin-right : 5%;
   }

 
`

const Subtitle = styled.h5`
font-size : 10px;
font-weight : 100;
text-transform : none;
left : 18px;
bottom : -5px;
@media screen and (min-width: 1920px) {
    font-size : 15px;
   }
`

const LessonsValidated = styled.div`
display: inline-flex;
color: black;
font-size: 15px;
line-height: 60px;
height: 150px;
width : 150%;
padding-top: 5px;
padding-bottom: 5px;
margin-right : -3%;
margin-left : -2%;
overflow : auto;
scrollbar-width: thin;
font-weight : 300;
:hover{
    animation: defilement-rtl 2s 3s ease-in-out;
  }

  @media screen and (max-width: 480px) {
 width : 180%;
 height : 120px;
}  

`

const StyledUl = styled.ul`
width : 100%;
list-style-type : none;
@media screen and (max-width: 480px) {
    list-style-type : disc;
    line-height : 250%;
  }
`
// pour amorcer le testing 
export function sum (a,b) {
    return a + b
  }

export function Lessons() {

    const [activeCategory, setActiveCategory] = useState('Front-end')
	const categories = lessonsList.reduce(
		(acc, elem) =>
			acc.includes(elem.category) ? acc : acc.concat(elem.category),
		[])

    const globalDuration = lessonsList.reduce(
        (total, currentValue) =>
            total = total + currentValue.duration,0);
    
    return (
    
    <ParentLessons>
        <LessonsDiv>
            <h4 className='h4' >Cours validés</h4> 
            <Subtitle> + {globalDuration} heures au total.</Subtitle>
            <Categories
				categories={categories}
				setActiveCategory={setActiveCategory}
				activeCategory={activeCategory}
			/>
		</LessonsDiv>

       <LessonsValidated>
        <StyledUl>
        {lessonsList.map(({ id, name, difficulty, duration, category }) =>
					!activeCategory || activeCategory === category ? (
							
                            <LessonsItem
                        id={id}
                        name={name}
                        duration={duration}
                        difficulty={difficulty}
                    />
                    
            	) : null
			)}
		</StyledUl>
        </LessonsValidated>
        
    </ParentLessons>

            
	)
}      
