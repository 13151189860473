export const lessonsList = [
	{
		name: 'Apprenez à utiliser la ligne de commande dans un terminal.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '1a',
	},
	{
		name: 'Découvrez les métiers de développeur.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '2a',
	},
	{
		name: 'Apprennez à créer votre site web avec HTML5 et CSS3.',
		category: 'Front-end',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:20,
		id: '3a',
	},
	{
		name: 'Gérez du code avec Git et Github.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '4a',
	},
	{
		name: 'Simplifiez-vous le CSS avec Sass.',
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:15,
		id: '5a',
	},
	{
		name: 'Comprendre le web.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '6a',
	},
	{
		name: 'Créer des animations CSS modernes.',
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:15,
		id: '7a',
	},
	{
		name: 'Créer des sites web responsive avec Bootstrap 5.',
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:10,
		id: '8a',
	},
	{
		name: 'Découvrez le fonctionnement des algorithmes.',
		category: 'Front-end',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:4,
		id: '9a',
	},
	{
		name: 'Apprenez à programmer avec Javascript.',
		category: 'Front-end',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:15,
		id: '10a',
	},
	{
		name: 'Écrivez du Javascript pour le web',
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:12,
		id: '11a',
	},
	{
		name: 'Mettez en place votre environnement front-end.',
		category: 'Front-end',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:4,
		id: '12a',
	},
	{
		name: 'Débuter avec React.',
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:8,
		id: '13a',
	},
	{
		name: 'Adoptez les API REST pour vos projets web.',
		category: 'Back-end',
		difficultyString: 'facile',
        difficulty: '⚡️',
		duration:4,
		id: '14a',
	},
	{
		name: 'Passez au full-stack avec Node, Express et MongoDB.',
		category: 'Back-end',
		difficultyString: 'Moyen',
        difficulty: '⚡️⚡️',
		duration:10,
		id: '15a',
	},
	{
		name: 'Devenez développeur agile.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:4,
		id: '16a',
	},
	{
		name: 'Mettez en place un système de veille informationnelle.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:4,
		id: '17a',
	},
	{
		name: 'Créer une maquette pour le développement web.',
		category: 'Front-end',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '18a',
	},
	{
		name: 'Développez vos soft skills.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '19a',
	},
	{
		name: 'Apprendre à apprendre.',
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:6,
		id: '20a',
	}
	,
	{
		name: "Testez l'interface de votre site.",
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:4,
		id: '21a',
	}
    ,
    {
		name: "Déboguez l'interface de votre site internet",
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:10,
		id: '22a',
	}
	,
	{
		name: "Créez un site moderne et professionnel avec Wordpress 5",
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:15,
		id: '23a',
	}
	,
	{
		name: "Créez des pages web dynamiques avec Javascript",
		category: 'Front-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:12,
		id: '24a',
	}
	,
	{
		name: "Concevez votre site web avec PHP et MySQL",
		category: 'Back-end',
		difficultyString: 'Moyenne',
        difficulty: '⚡️⚡️',
		duration:8,
		id: '25a',
	}
	,
	{
		name: "Gérer facilement vos projets informatiques",
		category: 'Divers',
		difficultyString: 'Facile',
        difficulty: '⚡️',
		duration:4,
		id: '26a',
	}
]