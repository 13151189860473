import React,{ useState } from "react";
import styled from 'styled-components'

import { Theorie } from "../Theorie/index"
import { Pratique }from  "../Pratique/index"
import { Contacts } from "../Contact/index"
import { Accueil } from "../Accueil/index"

import { Burger } from "../Burger/index"

import '../../utils/styles/navCards.css'

import ReactBlack from '../../assets/img/technos/React-Black-ico.png'

const Wrap = styled.div`
display : flex;
position : absolute;
justify-content : space-between;
top : 0;
left : 0;
width : 100%;


@media screen and (max-width: 480px) {
justify-content : center; 
align-items : center;
}
`

const StyledLogo = styled.img`
height : 50px;
margin : 30px; 
@media screen and (max-width: 480px) {
  height : 30px;
  left : 3%;
  top : 10%; 
  }
`

const StyledH1 = styled.div`
  padding: 30px;
  padding-top : 45px;
  position : relative;
  color: black;
  font-size: 20px;
  text-align: center;
  flex-basis : 50%;
`

const StyledH2 = styled.h2`
  padding: 30px;
  margin-right : 20px;
  font-wheight : 100;
  position : relative;
  white-space : nowrap;
  color: black;
  font-size: 20px;
  text-align: center;
  text-decoration: underline 0.02em rgba(255, 255, 255, 0);
  transition: text-decoration-color 300ms;
  :hover{
	cursor: pointer;
    text-decoration-color: rgba(128, 128, 128, 0.595);
    text-underline-offset: 2px;
  }
    `

const MobileResponsive = styled.div`
display : flex; 

@media screen and (max-width: 480px) {
display : none;
}
`



export function Header() {

  const [style, setStyle] = useState("TheorieClosed");
    
  const parentToChild = () => {
      if (style === ("TheorieOpen")) {
          setStyle("TheorieClosed");

        } else {
          setStyle("TheorieOpen");

        }

      if (styleP === ("PratiqueOpen")){
          setStyleP("PratiqueClosed");
        } 

      if (styleC === ("ContactsOpen")){
        setStyleC("ContactsClosed")
      }
      if (styleA === ("AccueilOpen")) {
        setStyleA("AccueilClosed");
      } 
      
    }


  const [styleP, setStyleP] = useState("PratiqueClosed");

  const parentToChildP = () => {

    if (styleP === ("PratiqueOpen")){
      setStyleP("PratiqueClosed");

    } else {
      setStyleP("PratiqueOpen");
    }
    if (style === ("TheorieOpen")) {
      setStyle("TheorieClosed");
    } 
    if (styleC === ("ContactsOpen")){
      setStyleC("ContactsClosed")
    }
    if (styleA === ("AccueilOpen")) {
      setStyleA("AccueilClosed");
    } 
  
   }
    ;

    const [styleC, setStyleC] = useState("ContactsClosed");

    const parentToChildC = () => {
  
      if (styleC === ("ContactsOpen")){
        setStyleC("ContactsClosed");

      } else {
        setStyleC("ContactsOpen");

      }
      if (style === ("TheorieOpen")) {
        setStyle("TheorieClosed");
      } 
      if (styleP === ("PratiqueOpen")) {
        setStyleP("PratiqueClosed");
      } 
      if (styleA === ("AccueilOpen")) {
        setStyleA("AccueilClosed");
      } 
     }
      ;
    
      const [styleA, setStyleA] = useState("AccueilClosed");

      const parentToChildA = () => {
    
        if (styleA === ("AccueilOpen")){
          setStyleA("AccueilClosed");
  
        } else {
          setStyleA("AccueilOpen");
  
        }
        if (style === ("TheorieOpen")) {
          setStyle("TheorieClosed");
        } 
        if (styleP === ("PratiqueOpen")) {
          setStyleP("PratiqueClosed");
        } 
        if (styleC === ("ContactsOpen")) {
          setStyleC("ContactsClosed");
        } 
       }
    ;
     
        
    

  return (
    <div>
      <Wrap>
        <StyledLogo src={ReactBlack} alt='Logo-React'/>
        <StyledH1>Développeur web - Front End</StyledH1>
        <MobileResponsive>
            <StyledH2 onClick={() => parentToChildA() }>À propos</StyledH2>
            <StyledH2 onClick={() => parentToChild() }>Théorie</StyledH2>
            <StyledH2 onClick={() => parentToChildP() }>Pratique</StyledH2>
            <StyledH2 onClick={() => parentToChildC() }>Contacts</StyledH2>
        </MobileResponsive>
        <Burger 
            parentToChildA={styleA} updateA={setStyleA}
            parentToChild={style} update={setStyle}
            parentToChildP={styleP} updateP={setStyleP}
            parentToChildC={styleC} updateC={setStyleC}
            />
      </Wrap>

      <Accueil parentToChildA={styleA} updateA={setStyleA}/>
      <Theorie parentToChild={style} update={setStyle}/>
      <Pratique parentToChildP={styleP} updateP={setStyleP}/>
      <Contacts  parentToChildC={styleC} updateC={setStyleC}/>
    </div>
  )
}


