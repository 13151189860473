import {React, useState} from 'react';
import styled from 'styled-components';

import PP from '../../assets/img/profile/ProfilPic.jpeg'
import LogoOpen from '../../assets/img/logos/Openclassrooms-logo.png'

import { AvantagesALT } from "../AvantagesALT/index";
import { CompetencesH } from '../CompetencesH/index';

import '../../utils/styles/navCards.css'

import RedDotImg from '../../assets/img/icons/RedDot.png'


const CardTitle = styled.h1`
background : transparent;
font-size : 25px;
margin-top : 5%;
font-weight : 300;
@media screen and (min-width: 1920px) {
    font-size : 35px;
  }

@media (max-width: 480px) {
  display : none;
  }

`
const RedDot = styled.img`
position : absolute;
z-index : 10;
width : 10px;
height : 10px;
top : 2%;
left : 1%;
opacity : 0.5;
`
const ParentWrap = styled.div`
display : flex;
height : 70%;
width : 90%;
margin-bottom : 2%;
font-size : 20px;


`
const AccueilDiv = styled.div `
display : flex;
flex-basis : 100%;
flex-direction : row;

@media (max-width: 480px) {
  flex-direction : column;
  }
`
const InfosAccueil = styled.div`
display : flex; 
flex-direction : column;
margin-left : 6%;
margin-top : 2%;

@media (max-width: 480px) {
  margin-left : 0%;
  margin-top : -15%;
  }

@media screen and (min-width: 1900px) {
  margin-top : 8%;
  }
`


const TopDiv = styled.div`
font-size : 20px;
line-height : 3%;
`
const RightTitle = styled.div`
font-size : 20px;
margin-right : 5px;
`
const RightTitleMD = styled.div`
display : flex;
flex-direction : row;
height : 20%;
@media (max-width: 480px) {
  display : none;
  }
`
const RightSubtitle = styled.p`
font-size : 12px;
`
const MidDiv = styled.div`
font-size : 12px;
margin-bottom : 8%;
`
const StyledList = styled.div`
display : flex;
flex-direction : row;
height : 20px;
font-weight : 300;
`
const Pbold = styled.p`
font-weight : 400;
margin-right : 4%;
`

const BottomDualDiv = styled.div`
display : flex;
justify-content : space-between;
font-weight : 100;
flex-direction : row;


`

const ID = styled.div`
display : flex;
flex-basis : 35%;
justify-content : center;
align-items : center;
margin-left : 2%;

@media (max-width: 480px) {
  justify-content : flex-end;
  margin-top : -8%;
  }
`

const GradientDiv = styled.div`
position : absolute;
height : 173px; 
width : 173px;
border-radius : 100%;
background: linear-gradient(to top, #CECECE, white);
filter: drop-shadow(2px 2px 2px #9E9E9E);

@media (max-width: 480px) {
  height 86.5px;
  width : 86.5px;
  margin-right : 1%;
  }
`
const ProfilPic = styled.img`
height : 160px;
width : 160px;
opacity : 0.9;


border : 6px solid transparent;
border-radius : 100%;

z-index : 2;

@media (max-width: 480px) {
  height 80px;
  width : 80px;
  }
`

 const OpenClassRooms = styled.img`
height : 12px;
opacity : 0.7;
margin-left : 3%;

 `

 const OpenClass = styled.div`
 display : flex;
 flex-direction : row;
 align-items : center;

 `


const ButtonDiv = styled.div`
border : solid 0.1px rgba(0, 0, 0, .2);
border-radius : 5px;
margin-right : 5%;
margin-top : 2%;
font-size : 12px;
padding-left : 3%;
padding-right : 3%;
padding-bottom : 1%;
filter: drop-shadow(0px 0.9px 1px #C0C0C0);
background-color : white;
height : 40%;
cursor : default;
box-shadow: 0px 0.5px 1px grey;
@media (max-width: 480px) {
  padding-left : 1%;
  padding-right : 1%;
  }
`
const P300 = styled.p`
font-weight : 300;
margin-top : -0.5%;
`

export function Accueil  ({parentToChildA, updateA})  {


  const RedDotClose = () => {
    if (parentToChildA === ("AccueilOpen")){
      updateA ("AccueilClosed");
    }
  }


  const [styleALT, setStyleALT] = useState("ALTClosed");

      const parentToChildALT = () => {
    
        if (styleALT === ("ALTClosed")){
          setStyleALT ("ALTOpen");
  
        } else {
          setStyleALT ("ALTClosed");
        }
      
        if (styleCPH === ("CPHOpen")){
          setStyleCPH("CPHClosed");
       }
      }
;
const [styleCPH, setStyleCPH] = useState("CPHClosed");

const parentToChildCPH = () => {

  if (styleCPH === ("CPHClosed")){
    setStyleCPH("CPHOpen");

  } else {
    setStyleCPH("CPHClosed");

  }
  if (styleALT === ("ALTOpen")) {
    setStyleALT("ALTClosed");
  }
}  
  ;

    return (
    <div className = {parentToChildA} >
        
       <RedDot src={RedDotImg} alt={"CloseWindow"} onClick={() => RedDotClose()}></RedDot>
       <CardTitle>À propos</CardTitle>
       <ParentWrap>
            <AccueilDiv>
              <ID>
                <GradientDiv>
                </GradientDiv>
                <ProfilPic src={PP} alt="Photo de profil"/>
              </ID>

              <InfosAccueil>
                <TopDiv>
                  <RightTitleMD>
                    <RightTitle>Développeur</RightTitle>
                    <P300>Front-end</P300>
                  </RightTitleMD>

                  <OpenClass>
                    <RightSubtitle>Admis à la formation en alternance</RightSubtitle>
                    <OpenClassRooms src={LogoOpen} alt="Openclassrooms"/>
                  </OpenClass>
                </TopDiv>

                <MidDiv>  
                  <StyledList>
                    <Pbold>Durée de l'alternance</Pbold>
                    <p>12  mois</p>
                  </StyledList>
                  <StyledList>
                    <Pbold>Type de contrat</Pbold>
                    <p>Professionalisation ou alternance</p>
                  </StyledList>
                  <StyledList>
                    <Pbold>Fréquence de l'enseignement</Pbold>
                    <p>Selon préférence employeur</p>
                  </StyledList>
                  <StyledList>
                    <Pbold>Réalisation des cours</Pbold>
                    <p>En distanciel</p>
                  </StyledList>
                  <StyledList>
                    <Pbold>Télétravail</Pbold>
                    <p>Ouvert au télétravail</p>
                  </StyledList>
                </MidDiv>
                <BottomDualDiv>
                  <ButtonDiv onClick={() => parentToChildALT() }>
                    <P300  >Avantages de l'alternance...</P300>
                  </ButtonDiv>
                  <ButtonDiv onClick={() => parentToChildCPH()}>
                  <P300>Compétences..</P300>
                  </ButtonDiv>
                </BottomDualDiv>
              </InfosAccueil>
            </AccueilDiv>
        </ParentWrap>    

        <AvantagesALT parentToChildALT={styleALT} updateALT={setStyleALT}/>
        <CompetencesH  parentToChildCPH={styleCPH} updateCPH={setStyleCPH} />
    </div>
            
	)
}      


