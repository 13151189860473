import {React} from 'react';
import styled from 'styled-components';
import { Lessons } from  "../Lessons/index"
import { Technos } from '../Technos/index';

import '../../utils/styles/navCards.css'

import RedDotImg from '../../assets/img/icons/RedDot.png'


const CardTitle = styled.h1`
background : transparent;
font-size : 25px;
margin-top : 5%;
font-weight : 300;
@media screen and (min-width: 1920px) {
    font-size : 35px;
  }

@media screen and (max-width: 480px) {
    display : none;
  }
`

const RedDot = styled.img`
position : absolute;
z-index : 10;
width : 10px;
height : 10px;
top : 2%;
left : 1%;
opacity : 0.5;
`


export function Theorie  ({parentToChild, update})  {
    
  const RedDotClose = () => {
    if (parentToChild === ("TheorieOpen")){
      update ("TheorieClosed");
    }
  }


    return (
    <div className = {parentToChild} >
        
       <RedDot src={RedDotImg} alt={"CloseWindow"} onClick={() => RedDotClose()}></RedDot>
       <CardTitle>Théorie</CardTitle>
       <Technos />
       <Lessons />
       
    </div>
            
	)
}      
