import React, { useEffect } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";


export function Island() {
  const gltf = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "models/island/scene.gltf"
  );
  
  useEffect(() => {
    gltf.scene.scale.set(0.5, 0.5, 0.5);
    gltf.scene.position.set(-2, 0.3, -3);
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf]);

  
 
  return <primitive object={gltf.scene} />;
}
