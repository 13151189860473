export const avantagesList = [
	{
		name: 'Former à ses outils et sa culture',
		category: 'Employeur',
		id: '1e',
	},
    {
		name: "Préparer l'embauche pour des besoins futurs",
		category: 'Employeur',
		id: '2e',
	},
    {
		name: 'Maîtrise des coups salariaux',
		category: 'Employeur',
		id: '3e',
	},
    {
		name: "Bénéfice d'aides et exonérations spécifiques",
		category: 'Employeur',
		id: '4e',
	},
    {
		name: 'Des formalités administratives simplifiées',
		category: 'Employeur',
		id: '5e',
	},
    {
		name: "Apprendre auprès d'une équipe de professionnels",
		category: 'Alternant',
		id: '1f',
	},
    {
		name: "Travailler pour de véritables projets client",
		category: 'Alternant',
		id: '2f',
	},
    {
		name: "Apprendre et appliquer immédiatement",
		category: 'Alternant',
		id: '3f',
	},
	{
		name: "Se rendre utile auprès d'une équipe",
		category: 'Alternant',
		id: '4f',
	},
	{
		name: "Pouvoir financer ses études",
		category: 'Alternant',
		id: '5f',
	}
]