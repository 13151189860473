import styled from 'styled-components';

const ParentDiv = styled.div`
display : flex;

@media (max-width: 480px) {
    width : 50%;
    margin-left : 20%;
    white-space : normal;
    }
`

const StyledLi = styled.li`
margin-left : 3%;
@media screen and (min-width: 1920px) {
    font-size : 20px;
    margin-left : 5%;
   }

@media (max-width: 480px) {
    font-weight : 300;
}
`

const PrecisionsLessons = styled.span`
font-size: 10px;
`

const ThunderShadow = styled.span`
filter: drop-shadow(4px 4px 4px #5e5e5e);
font-size : 8px;
`

function LessonsItem({ id, category, name, difficulty, duration}) {
	return (
    <ParentDiv>
		<StyledLi>{name} - <PrecisionsLessons> {duration} heures </PrecisionsLessons> 
            <ThunderShadow>
                {difficulty}
            </ThunderShadow> 
        </StyledLi>
    </ParentDiv>
	)
}

export default LessonsItem