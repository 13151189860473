import Académie from "../assets/img/projets/ProjetAcadémie.png"
import Portfolio from "../assets/img/projets/Test.png"

import ReactIco from "../assets/img/technos/React-ico.svg"
import ThreeJsIco from "../assets/img/technos/ThreeJs-ico.svg"

export const images = [
  { title: "Académie des habitudes",
    subtitle: "Plusieurs algorithmes issus d'un travail de recherche sur les données en neurosciences pour veiller sur des objectifs.",
    type : "Application React Full stack",
    statut : "(en cours)",
    img: Académie,
    technos: ReactIco,
  },

  {
    title: "Vitrine",
    subtitle: "Mon tout premier site codé de A à Z en suivant les cours libres OpenClassroom et quelques tutoriels.",
    type : "Application React Three",
    statut : "(en cours)",
    img: Portfolio,
    technos: ThreeJsIco,
  },
];