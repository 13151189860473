import React, { useEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";


export function Penguin() {
  const gltf = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "models/penguin/scene.gltf"
  );
  
  useEffect(() => {
    gltf.scene.scale.set(0.02, 0.02, 0.02);
    gltf.scene.position.set(-2.5, 0.48, -5);
    gltf.scene.rotateY(28);
  
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf]);



  const gltf2 = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "models/penguin/scene.gltf"
  );
  
  useEffect(() => {
    gltf2.scene.scale.set(0.02, 0.02, 0.02);
    gltf2.scene.position.set(-2.5, 0.48, -4);
    gltf2.scene.rotateY(28);
  
    gltf2.scene.traverse((object2) => {
      if (object2 instanceof Mesh) {
        object2.castShadow = true;
        object2.receiveShadow = true;
        object2.material.envMapIntensity = 20;
      }
    });
  }, [gltf2]);

  
  
 
  return <primitive object={gltf.scene} object2={gltf2.scene}></primitive> 
  ;
}
