import {React, useState} from 'react';
import styled from 'styled-components';

import '../../utils/styles/navCards.css'

import AvantagesALTItems from '../AvantagesALTItems/index'
import { avantagesList } from '../../datas/avantagesList'


const ParentWrap = styled.div`
display : flex;
height : 100%;
width : 100%;
font-size : 12px;
align-items : center;
justify-content : center;
margin-top : 20%;
`

const AvantagesDiv = styled.div `
display : flex;
font-weight : 300;
padding-right: 5%;
height : 100%;
`
const AvantagesUL = styled.ul`
@media screen and (min-width: 1900px) {
    margin-top : 32%;
    justify-content : center;
   }
`

const StyledDiv = styled.div`
height : 100%;
z-index : -1;
`

export function AvantagesALT  ({parentToChildALT})  {
    
    const [activeCategory, setActiveCategory] = useState('Employeur')

    const [stateA, setStateA] = useState("AlternantSelected");

    const AlternantStyle = () => {
        if (stateA === ("AlternantNotSelected")) {
            setStateA("AlternantSelected");
            setActiveCategory('Alternant');

        } else {
            setStateA("AlternantNotSelected");
          } 

          if (styleE === ("EmployeurSelected")){
            setStyleE("EmployeurNotSelected")
            }
          
        }


    const [styleE, setStyleE] = useState("EmployeurSelected");

    const EmployeurStyle = () => {
        if (styleE === ("EmployeurNotSelected")) {
            setStyleE("EmployeurSelected");
            setActiveCategory('Employeur');
        } else {
            setStyleE("EmployeurNotSelected");
          } 
        if (stateA === ("AlternantSelected")){
        setStateA("AlternantNotSelected")
        }
        }

   
    return (
    <div className = {parentToChildALT} >
       
       <div className = {stateA} onClick={(e) => AlternantStyle()}>
                <h4> Alternant </h4>
            </div>

            <div className = {styleE} onClick={(e) => EmployeurStyle()}>
                <h4> Employeur </h4>
            </div>


       <ParentWrap>
            <AvantagesDiv>
             <AvantagesUL>
             <StyledDiv>
                    {avantagesList.map(({ id, name, category }) =>
					!activeCategory || activeCategory === category ? (
							
                            <AvantagesALTItems
                        id={id}
                        name={name}
                    />
                    
            	) : null
			)}
		</StyledDiv>
             </AvantagesUL>
            </AvantagesDiv>
        </ParentWrap>
    </div>
            
	)
}      


