import React, { useState } from 'react';
import styled from 'styled-components';
import MobileNav from '../MobileNav/index';


const StyledBurger = styled.div`
  width: 2rem;
  height: 1.5rem;
  top: 6.5%;
  right: 8%;
  z-index: 20;
  display: none;
  flex-basis : 20%;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 1.5rem;
    height: 0.10rem;
    background-color: ${({ open }) => open ? '#ccc' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export function Burger ({
    parentToChildA, parentToChild, parentToChildC, parentToChildP,
    updateA, update, updateC, updateP}) 
    
    
    {
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <MobileNav 
      open={open} setOpen={setOpen} 
      parentToChildA={parentToChildA} updateA={updateA}
      parentToChild={parentToChild} update={update}
      parentToChildP={parentToChildP} updateP={updateP}
      parentToChildC={parentToChildC} updateC={updateC}
       />
      
    </>
  )
}

