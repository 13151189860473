 export const skillsList = [
	{
		name: 'Développer un site internet',
		category: 'CompétencesPro',
		id: '1c',
	},
    {
		name: 'Rechercher des solutions',
		category: 'CompétencesPro',
		id: '2c',
	},
    {
		name: 'Personnaliser un parcours utilisateur',
		category: 'CompétencesPro',
		id: '3c',
	},
    {
		name: 'Travailler et communiquer en équipe',
		category: 'CompétencesPro',
		id: '4c',
	},
    {
		name: 'Organiser des événements',
		category: 'CompétencesPro',
		id: '5c',
	},
	{
		name: "Autonome",
		category: 'Quality',
		id: '1d',
	},
	{
		name: "Pro-actif",
		category: 'Quality',
		id: '2d',
	},
	{
		name: "Créatif",
		category: 'Quality',
		id: '3d',
	},
	{
		name: "Débrouillard",
		category: 'Quality',
		id: '4d',
	},
	{
		name: "Endurant",
		category: 'Quality',
		id: '5d',
	},
]