import styled from 'styled-components';
import '../../utils/styles/navCards.css';

const TechnosDisplay = styled.div`
	display: flex;
	align-items : center;
	height: 100%;
	background-color: transparent;

	@media screen and (max-width: 480px) {
		overflow : auto;

	}
	
`

const TechnosCover = styled.div`
	display: flex;
	padding : 20px;
	width : 25px;
    height : 25px;
	@media screen and (min-width: 1920px) {
		height : 50px;
		width : 50px;
	  }
	
	@media screen and (max-width : 480px) {
	padding : 10px;
	 }
	
`
const TechnosImg = styled.img`
	  display : flex;
	  width : 30px;
	  filter: drop-shadow(4px 4px 4px #5e5e5e);
      animation: Intro ease 1.5s;
	  @media screen and (min-width: 1920px) {
		height : 50px;
		width : 50px;
	  }

	 

	  
`

export function TechnosItems({ id,cover, name, since, theory, practice }) {
	return (
    <TechnosDisplay>
		<TechnosCover>
			<TechnosImg src={cover} alt="name" /> 
		</TechnosCover>
    </TechnosDisplay>
	)
}

