import React, { useEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";


export function Seagull() {
  const gltf = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "models/seagull/scene.gltf"
  );
  
  useEffect(() => {
    gltf.scene.scale.set(0.03, 0.03, 0.03);
    gltf.scene.position.set(-1, 0.35, -5.2);
    gltf.scene.rotateY(28);
  
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf]);

  
 
  return <primitive object={gltf.scene} />;
}
