import React, { Suspense } from "react";
import { useProgress } from "@react-three/drei"
import { Canvas } from "@react-three/fiber";
import styled from "styled-components";

import { useState } from "react";

import { ThemeProvider } from "./utils/context";

import {
  CubeCamera,
  OrbitControls,
  PerspectiveCamera,
  Sky,
  Stars,
} from "@react-three/drei";

import "./style.css";
import "./utils/styles/loader.css"


import { Island } from "./components/Three/Island"
import { Titanic } from "./components/Three/Titanic"
import { PolarBear } from "./components/Three/PolarBear";
import { Shark } from "./components/Three/Shark"
import { Penguin } from "./components/Three/Penguin"
import { Submarine } from "./components/Three/Submarine";
import { Seagull } from "./components/Three/Seagull";
import { Fishes } from './components/Three/Fishes';
import { Plane } from './components/Three/Plane.js/index.js';
import { Wolf } from './components/Three/Wolf';

import { Header } from "./components/Header/index"


const ParentLoader  = styled.div`

position : absolute;
width : 100%;
height : 100%;
z-index : -5;
background : transparent;

display : flex;
flex-direction : row;
justify-content : space-evenly; 
align-items : center;

text-align : center;  
color : black


@media screen and (max-width: 480px) {
  flex-direction : column;
  justify-content : center;
}

`

const LoaderAnimation = styled.div`
position : absolute; 
display : flex; 
margin-bottom : 10%;
`

const Progress = styled.p`
margin-bottom : -8%;
`



function Loader() {

  const { progress } = useProgress()
  return (
  <ParentLoader>
    <LoaderAnimation>
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
    </LoaderAnimation>
    <Progress>{progress} % du chargement réalisé.</Progress>
  </ParentLoader>
  )
}
  

const NameWrap = styled.div`
position : absolute;
z-index : 3;
left : 5%;
top : 85%;
font-size : 12px;
line-height : 25%;
letter-spacing: 5px;
`
const Familyname = styled.h1`
font-weight : 300;
font-size : 20px;
`

function CarShow() {
  return (
    <>
      <OrbitControls 
        target={[0, 0.35, 0]}
        maxPolarAngle={1.60}
      />

      <PerspectiveCamera makeDefault fov={50} position={[3, 2, 5]} />

      <color args={[0, 0, 0]} attach="background" /> 
      
      <CubeCamera resolution={256} frames={Infinity}>
        {(texture) => (
          <>
               <Island />
               <Titanic/>
               <PolarBear />
               <Shark />
               <Penguin />
               <Submarine />
               <Seagull />
               <Fishes />
               <Plane />
               <Wolf />
          </>
        )}
      </CubeCamera>
      

      <spotLight
        color={[1, 1, 1]}
        intensity={2}
        angle={2}
        penumbra={0.5}
        position={[5, 10, 0]}
        castShadow
        shadow-bias={-0.0001}
      />
      <spotLight
        color={[1, 1, 1]}
        intensity={1}
        angle={0.6}
        penumbra={0.5}
        position={[-5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />
      
      <ambientLight intensity={0.2} />
    </>
  );
}




function App() {

  const [Theme, setTheme] = useState("Sky");

  const ThemeSetting = () =>  {

    
      if (Theme === ("Sky")){
        return (
          <Sky />
        )
      } else {
        return (
          <Stars />
        )
      }
  }


  const ThemeChange = () => {

    if (Theme === ("Sky")) {
      setTheme("Stars");
  } else {

    if (Theme === ("Stars"))
      setTheme("Sky")
  }
}

  return (
    <Suspense fallback={<Loader />}>
     <ThemeProvider>
      <Canvas shadows>
         <CarShow />
         <Island />
         <Titanic />
         <PolarBear />
         <Shark />
         <Penguin />
         <Submarine />
         <Seagull />
         <Fishes />
         <Plane />
         <Wolf />
         <ThemeSetting />
       </Canvas>
     

      <Header/>
     
      <NameWrap>
        <h2  onClick={ThemeChange}> Sébastien</h2>
        <Familyname>Hartmann</Familyname>
      </NameWrap>

      </ThemeProvider>
    </Suspense>
  );
}

export default App;


