import html from '../assets/img/technos/Html-ico.svg'
import css from '../assets/img/technos/Css-ico.svg'
import js from '../assets/img/technos/Js-ico.svg'
import figma from '../assets/img/technos/Figma-ico.svg'
import github from '../assets/img/technos/Github-ico.svg'
import sass from '../assets/img/technos/Sass-Pink-ico.svg'
import react from '../assets/img/technos/React-ico.svg'
import postman from '../assets/img/technos/Postman-ico.svg'
import shopify from '../assets/img/technos/Shopify-ico.svg'
import ps from '../assets/img/technos/PhotoShop-ico.svg'
import lr from '../assets/img/technos/LightRoom-ico.svg'
import node from '../assets/img/technos/Node-ico.svg'
import mongodb from '../assets/img/technos/MongoDb-ico.svg'
import threeJs from '../assets/img/technos/ThreeJs-ico.svg'
import wordpress from '../assets/img/technos/Wordpress-ico.svg'
import php from '../assets/img/technos/Php-ico.png'
import mysql from '../assets/img/technos/MySql-ico.svg'

export const technosList = [
	{
		name: 'HTML 5',
		category: 'Front-end',
		cover: html,
        since: 'Mai 2022'
	},
	{
		name: 'CSS3',
		category: 'Front-end',
		cover: css,
        since: 'Mai 2022'
	},
	{
		name: 'Javascript',
		category: 'Front-end',
		cover: js,
        since: 'Mai 2022'
	},
	{
		name: 'Figma',
		category: 'Utilitaires',
		cover: figma,
        since: 'Mai 2022'
	},
	{
		name: 'Sass',
		category: 'Front-end',
		cover: sass,
        since: 'Mai 2022'
	},
	{
		name: 'React',
		category: 'Front-end',
		cover: react,
        since: 'Juin 2022'
	},
	{
		name: 'GitHub',
		category: 'Utilitaires',
		cover: github,
        since: 'Mai 2022'
	},

	{
		name: 'Photoshop',
		category: 'Utilitaires',
		cover: ps,
		since: '2020'
	},

	{
		name: 'Lightroom',
		category: 'Utilitaires',
		cover: lr,
		since: '2020'
	},
	{
		name: 'Postman',
		category: 'Utilitaires',
		cover: postman,
		since: 'Juin 2022'
	},
	{
		name: 'Shopify',
		category: 'CMS',
		cover: shopify,
		since: '2019'
	},
	{
		name: 'Node',
		category: 'Back-end',
		cover: node,
		since: '2022'
	},
	{
		name: 'MongoDB',
		category: 'Back-end',
		cover: mongodb,
		since: '2022'
	},
    {
		name: 'ThreeJs',
		category: 'Front-end',
		cover: threeJs,
		since: 'Août 2022'
	},
	{
		name: 'Wordpress',
		category: 'CMS',
		cover: wordpress,
		since: 'Septembre 2022'
	}
	,
	{
		name: 'Php',
		category: 'Back-end',
		cover: php,
		since: 'Fin septembre 2022'
	}
	,
	{
		name: 'MySql',
		category: 'Back-end',
		cover: mysql,
		since: 'Fin septembre 2022'
	}




]