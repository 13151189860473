import React from "react";
import styled from "styled-components";


const StyledSelect = styled.select`
display : flex;
font-family : Lato, sans-serif;
text-align : center;
justify-content : center;
align-items : center;
font-size : 12px;
font-weight : 300;
background : white;
height : 20px;
width : 120px;
line-height : 20;
text-decoration : none;
border-radius : 5px;
border : solid 0.1px rgba(0, 0, 0, .2);
color : black;
filter: drop-shadow(0px 0.9px 1px #C0C0C0);
padding-bottom : 1%;
margin-top : 2%;
justify-content : center;
text-decoration: underline 0.02em rgba(255, 255, 255, 0);
transition: text-decoration-color 300ms;
:hover{
	animation: opacityOutAll ease-out 0.9s forwards;
	background: transparent;
	border : transparent;
	color: rgb(0, 0, 0);
	cursor: pointer;
    text-decoration-color: Grey;
    text-underline-offset: 2px;
  }
`

const StyleWrap = styled.div `
display : flex; 
justify-content : center;
`

function Categories({ setActiveCategory, categories, activeCategory }) {
	return (
		<StyleWrap>
			<StyledSelect
				value={activeCategory}
				onChange={(e) => setActiveCategory(e.target.value)}
			>
				{categories.map((cat) => (
					<option key={cat} value={cat}>
						{cat}
					</option>
				))}
			</StyledSelect>
		</StyleWrap>
	)
}

export default Categories