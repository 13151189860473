import React,{ useState } from "react";
import styled from 'styled-components';

import  Github  from '../../assets/img/technos/Github-ico.svg'
import  Linkedin  from '../../assets/img/socialNetwork/Linkedin-ico.svg'
import  Twitter  from '../../assets/img/socialNetwork/Twitter-ico.svg';

import CocheIco from '../../assets/img/icons/coche.png'
import RedDotImg from '../../assets/img/icons/RedDot.png'


import '../../utils/styles/navCards.css'



const CardTitle = styled.h1`
background : transparent;
font-size : 25px;
margin-top : 5%;
margin-bottom : 5%;
font-weight : 300;
@media screen and (min-width: 1920px) {
    font-size : 35px;
  }
`
const RedDot = styled.img`
position : absolute;
z-index : 10;
width : 10px;
height : 10px;
top : 2%;
left : 1%;
opacity : 0.5;
`


const ParentWrap = styled.div`
display : flex;
height : 70%;
width : 90%;
margin-bottom : 2%;
font-size : 20px;

@media screen and (min-width: 1900px) {
    margin-top : 10%;
}
`
const ContactsDiv = styled.div `
display : flex;
flex-basis : 100%;
flex-direction : column;
`
const RSnavBar = styled.div`
display : flex;
flex-basis : 100%;
justify-content : space-around;
`
const RSimg = styled.img`
display : flex;
height : 30px;
width : 30px;
filter: drop-shadow(4px 4px 4px #5e5e5e);
z-index: 5;
:hover {
    cursor : pointer;
}
`
const InfosContacts = styled.div`
display : flex; 
flex-direction : row;
`

const ColonneG = styled.div`
@media screen and (max-width : 480px){
    display : none;
}
`
const ColonneD = styled.div`
margin-left : 10%;
@media screen and (max-width : 480px){
margin-top : -60%;
font-size : 15px;
text-align : center;
justify-content : center;
}
`

const Mail = styled.p`
: hover {
    cursor : pointer;
}
`

const Coche = styled.img`
width : 10px;
height : 10px;
margin-top 6px;

@media screen and (max-width : 480px){
    margin-left : 5%;
}
`
const TexteCoche = styled.p`
font-size : 8px;
`
export function Contacts ({parentToChildC, updateC})  {
    
    const RedDotClose = () => {
        if (parentToChildC === ("ContactsOpen")){
          updateC ("ContactsClosed");
        }
      }

    const [styleM, setStyleM] = useState("Unclicked");
    
    const CopyClick = () => {

        if (styleM === ("Unclicked")) {
            setStyleM("Click");
            navigator.clipboard.writeText("contact@sebastien-hartmann.com");

        } else { 
            setStyleM("Unclicked")
        }

    }

    return (
    <div className = {parentToChildC} >
       <RedDot src={RedDotImg} alt={"CloseWindow"} onClick={() => RedDotClose()}></RedDot>
       <CardTitle>Contacts</CardTitle>
       <ParentWrap>
            <ContactsDiv>
                <RSnavBar>
                    <a href="https://github.com/Halfligh" target="_blank" rel='noreferrer'>
                        <RSimg src={Github} alt='Github' />
                    </a>
                    <a href="https://linkedin.com/in/sébastien-hartmann-b1bb9a248" target="_blank" rel='noreferrer' >
                        <RSimg src={Linkedin} alt='Likedin' />
                    </a>
                    <a href="https://twitter.com/HalflighCode" target="_blank" rel='noreferrer'>
                        <RSimg src={Twitter} alt='Twitter' />
                    </a>
                </RSnavBar>

                <InfosContacts>
                    <ColonneG>
                        <p>Adresse-mail</p>
                        <p>Ligne principale</p>
                        <p>Mobilité</p>
                    </ColonneG>

                <ColonneD>  
                    <Mail onClick={() => CopyClick()}>contact@sebastien-hartmann.com</Mail>
                        <div className = {styleM}>
                        <TexteCoche >Copiée dans le presse-papier</TexteCoche>
                            <Coche src={CocheIco} alt="Email Copié dans le presse papier" />
                        </div>
                            <p>06.79.64.16.24</p>
                            <p>France - Toutes régions </p>
                </ColonneD>
            </InfosContacts>
        </ContactsDiv>
    </ParentWrap>
      
       
    </div>
            
	)
}      
