import React, { useState } from "react";
import styled from "styled-components";


import { images } from "../../datas/carousselList";

import IconLeft from '../../assets/img/icons/IconGauche.png'
import IconRight from '../../assets/img/icons/IconDroite.png'

import '../../utils/styles/navCards.css'

const ParentWrap = styled.div`
display : flex;
height : 70%;
width : 90%;
margin-bottom : 2%;

@media screen and (max-width : 480px){
  flex-direction : column-reverse;
}
`
const CarousselDiv = styled.div`
display : flex;
flex-basis : 50%;
justify-content : space-around;
align-items : center;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius : 10px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);

height : 85%;

margin-right : 15%;
margin-top : 3.5%;

overflow : visible;
opacity : 0;
animation: Intro forwards 2s;

@media screen and (max-width : 480px) {
margin : 5%;
flex-basis : 100%;
position : absolute;
height : 35%;
width : 50%;
margin-bottom : -10%;
margin-left : 20%;
}

`
const LeftControl = styled.div`
display : flex;
width : 100%;
color : white;
opacity : 0.7;
margin-left : 0%;

:hover{
	cursor: pointer;
  opacity : 1;
  transition: opacity 2s ease-out;
  }
`
const RightControl = styled.div`
display : flex;
position : relative;
color : white;
margin-right : 5%;

:hover{
	cursor: pointer;
  opacity : 1;
  transition: opacity 2s ease-out;
  }
`
const Infos = styled.div`
display : flex;
flex-direction : column;
flex-wrap : wrap;
position : absolute;
font-size : 20px;
margin-left : 50%;
margin-top : 2.5%;

@media screen and (max-width : 480px) {
  margin-left : 0%;
  top : 5%;
  flex-basis : 100%;
  justify-content : center;
`
const Title = styled.div`
display : flex;
width : 89%;
white-space: normal;
justify-content : center;
margin-top : -5%;
@media screen and (min-width: 1920px) {
  font-size : 25px;
  width : 89%;
  justify-content : center;
}
`
const TitleH3 = styled.h3`
font-weight : 400;
font-size : 18px;
margin-bottom : 10%;
`

const Description = styled.div `
display : flex;
flex-direction : column;
width : 89%;
white-space: normal;
font-size : 15px;
justify-content : center;
margin-top : -5%;
@media screen and (min-width: 1920px) {
  font-size : 20px;
  text-align : center
}

@media screen and (max-width : 480px) {
font-size : 12px;
font-weight : 300;
}
`

const Sub = styled.p`
text-align: justify;
@media screen and (min-width: 1920px) {
 margin-top : 15%;
 margin-bottom : 15%;
}
`

const TechLogos = styled.img `
height : 30px;
display : flex;
margin-top : 4%;
margin-left : 5%;
`
const Column = styled.div `
display : flex;
flex-direction : row;
justify-content : center;
`

const StatutP = styled.p `
text-align : center;
margin-top : -5%;
font-size : 10px;
`

const IconLogo = styled.img`
height : 25px;
width : 25px;
margin : 5%;
`


function Caroussel() {
  const [currImg, setCurrImg] = useState(0);


  return (
    <ParentWrap>
      <CarousselDiv
        style={{ backgroundImage: `url(${images[currImg].img})` }}
      >
        <LeftControl
          onClick={() => {
            currImg > 0 && setCurrImg(currImg - 1);
          }}
        >
          <IconLogo src={IconLeft} alt="Imageprécédente" />
        </LeftControl>
      
        <RightControl
          onClick={() => {
            currImg < images.length - 1 && setCurrImg(currImg + 1);
          }}
        >
          <IconLogo src={IconRight} alt="Imagesuivante" />
        </RightControl>

      </CarousselDiv>

      <Infos>
          <Title>
            <TitleH3>{images[currImg].title}</TitleH3>
          </Title>
          
          <Description>
            <Sub>{images[currImg].subtitle}</Sub>
            
            <Column>
              <p>{images[currImg].type}</p>
              <TechLogos src ={images[currImg].technos} alt="ReactLogo"/>
            </Column>
            
            <StatutP>{images[currImg].statut}</StatutP>
            
          </Description>
          
      </Infos>

    </ParentWrap>
  );
}

export default Caroussel;

