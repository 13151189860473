import {React, useState} from 'react';
import styled from 'styled-components';
import { skillsList } from '../../datas/skillsList'

import '../../utils/styles/navCards.css'

import Skillsitems from '../CompetencesHItems/index';


const ParentWrap = styled.div`
display : flex;
height : 100%;
width : 80%;
font-size : 12px;
align-items : center;
justify-content: center;
`

const AvantagesDiv = styled.div `
display : flex;
font-weight : 300;
padding-right: 5%;
height : 50%;
overflow : scroll;
margin-left : -8%;

`
const AvantagesUL = styled.ul`

`
const StyledDiv = styled.div`
height : 100%;
z-index : -1;

`
export function CompetencesH ({parentToChildCPH })  {
    
    const [activeCategory, setActiveCategory] = useState('CompétencesPro')

        const [state, setState] = useState("CompSelected");
    
        const CompStyle = () => {
            if (state === ("CompNotSelected")) {
                setState("CompSelected")
                setActiveCategory('CompétencesPro');
              } else {
                setState("CompNotSelected");
              }
              if (stateQ === ("QualitySelected")){
                setStateQ("QualityNotSelected")
              }
            }
      
        const [stateQ, setStateQ] = useState("QualityNotSelected");
        
            const QualityStyle = () => {
                if (stateQ === ("QualityNotSelected")) {
                    setStateQ("QualitySelected")
                    setActiveCategory('Quality');
                  } else {
                    setStateQ("QualityNotSelected");
                  }

                if (state === ("CompSelected")){
                  setState("CompNotSelected")
                }
              }

    return (
    <div className = {parentToChildCPH} >
            <div className = {stateQ} onClick={(e) => QualityStyle()}>
                <h4> Qualités </h4>
            </div>

            <div className = {state} onClick={(e) => CompStyle()}>
                <h4> Compétences </h4>
            </div>
            
      
        

       <ParentWrap>
            <AvantagesDiv>
             <AvantagesUL>
             <StyledDiv>
                    {skillsList.map(({ id, name, category }) =>
					          !activeCategory || activeCategory === category ? (
                            <Skillsitems
                              id={id}
                              name={name}
                            />      
            	      ) : null
		                	)}
		          </StyledDiv>
             
             </AvantagesUL>
            </AvantagesDiv>
        </ParentWrap>
        </div>
     
            
	)
}      
