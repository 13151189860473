import styled from 'styled-components';
import '../../utils/styles/keyframes.css'

const ParentDiv = styled.div`
display : flex;
white-space : normal;
animation: Intro ease-in 1.2s;
`


function CompetencesHitems({ id, category, name}) {
	return (
    <ParentDiv>
		<p>{name}</p>
    </ParentDiv>
	)
}

export default CompetencesHitems