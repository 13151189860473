import styled from 'styled-components';
import '../../utils/styles/keyframes.css'

const ParentDiv = styled.div`
display : flex;
animation: Intro ease-in 1.2s;
white-space : normal;
@media (max-width: 480px) {
   
    }
`



function AvantagesALTItems({ id, category, name}) {
	return (
    <ParentDiv>
		<p>{name}</p>
    </ParentDiv>
	)
}

export default AvantagesALTItems
