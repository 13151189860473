import React from 'react';
import styled from 'styled-components';



const Ul = styled.ul`
  list-style: none;
  display: none;
  flex-flow: row nowrap;
  z-index : 2;

  li {
    padding: 18px 10px;
    margin-left : -10%;
  }
  @media (max-width: 480px) {
    display : flex;
    flex-flow: column nowrap;
    justify-content : center;
    text-align : center;
    background-color: white;
    border-radius : 10px;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100%;
    width: 70%;
    transition: transform 0.3s ease-in-out;
    li {
      color: black;
      font-size : 25px;
    }
  }
`;

export function MobileNav ({ open, setOpen, updateA, update, updateC, updateP}) {


      const CloseAndDisplayA = () =>{
        if (open === (true)){
            setOpen(false);
            update("TheorieClosed");
            updateC("ContactsClosed");
            updateP("PratiqueClosed");
            updateA ("AccueilOpen");
          }
    }

      const CloseAndDisplay = () =>{
        if (open === (true)){
            setOpen(false);
            updateA("AccueilClosed");
            updateC("ContactsClosed");
            updateP("PratiqueClosed");
            update("TheorieOpen");
          }
    }

      const CloseAndDisplayP = () =>{
        if (open === (true)){
            setOpen(false);
            updateA("AccueilClosed");
            updateC("ContactsClosed");
            update("TheorieClosed");
            updateP ("PratiqueOpen");
          }
    }
      
      const CloseAndDisplayC = () =>{
        if (open === (true)){
            setOpen(false);
            updateA("AccueilClosed");
            update("TheorieClosed");
            updateP ("PratiqueClosed");
            updateC ("ContactsOpen");
          }
    }

    return (
    <Ul open={open}>
      <li onClick={() => CloseAndDisplayA ()} >À propos</li>
      <li onClick={() => CloseAndDisplay ()}>Théorie</li>
      <li onClick={() => CloseAndDisplayP ()}>Pratique</li>
      <li onClick={() => CloseAndDisplayC ()}> Contacts</li>
    </Ul>
  )
}

export default MobileNav