import React from 'react';
import styled from 'styled-components';
import { useState } from 'react'
import { technosList } from '../../datas/technosList';
import Categories from '../Catégories/index'
import { TechnosItems } from '../TechnosItems/index';


const ParentTechnos = styled.div `
        display: flex;
        align-items: center;
        height : 100%;
        z-index: 3;
        color: black;
        padding-bottom: 15px;
        @media screen and (min-width: 1920px) {
            padding-bottom : 0%;
          }

        @media screen and (max-width: 480px){
            flex-direction : column-reverse;
        }
        `

const LeftColUl = styled.div`
width : auto;
display : flex;
flex-direction : row;
overflow : auto;

@media screen and (max-width : 480px) {
    margin-bottom : -10%;
}
`

const TechnosDiv = styled.div `
    flex-direction : row;
    z-index: 2;
    flex-basis: 30%;
    text-align: center;
    padding-right: 5%;
    padding-left: 5%;
    margin: 0px;
    font-family: Lato, sans-serif;
    text-transform: uppercase;
`

const RightCol = styled.div`
    display: flex;
    height : 30px;
    width : 200px;
    positon: absolute;
    @media screen and (min-width: 1920px) {
        height : 50px;
        margin-left : 5%;
      }

    @media screen and (max-width : 480px) {
        justify-content : center;
        margin-top : 15%;
        }
`



export function Technos() {


    const [activeCategory, setActiveCategory] = useState('Front-end')
	const categories = technosList.reduce(
		(acc, elem) =>
			acc.includes(elem.category) ? acc : acc.concat(elem.category),
		[]
	)

    return (

        
    <ParentTechnos>
        <LeftColUl>
        
        {technosList.map(({ id, name, cover, since, category, theory, practice }) =>
					!activeCategory || activeCategory === category ? (
							
                            <TechnosItems
                        id={id}
                        name={name}
                        cover={cover}
                        since={since}
                        theory={theory}
                        practice={practice}
                    />
                    
            	) : null
			)}
       </LeftColUl>

       <RightCol>
            <TechnosDiv>
                <Categories
				categories={categories}
				setActiveCategory={setActiveCategory}
				activeCategory={activeCategory}
			    />
            </TechnosDiv>
        
        </RightCol>
        
    </ParentTechnos>
    )          
}      

        